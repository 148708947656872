<template>
  <b10-base>
    <b10-subheader
      label="Datos del contrato"
      :icon="$vuetify.icons.values.contratos"
    />
    <b10-text-field
      v-model="form.objeto"
      clearable
      flat
      label="Objeto"
      :validation-rules="formRules.objeto"
      :with-validation-provider="true"
    />
    <b10-autocomplete
      v-model="form.tcontrato"
      :items="tcontratos"
      item-text="descripcion"
      label="Tipo de contrato"
      clearable
      return-object
      no-filter
      :with-validation-provider="true"
      :validation-rules="formRules.tcontrato"
      @change="tcontratoSelected"
    />

    <!-- Inicio Extraordinario -->

    <b10-subheader
      label="Inicio extrardinario"
      :icon="$vuetify.icons.values.calendar"
    />
    <b10-checkbox
      v-model="form.inicio_extraordinario"
      name="inicio_extraordinario"
      label="Inicio con carácter urgente"
      :with-validation-provider="true"
      @change="inicioExtraordinarioSelected"
    />
    <b10-date-time-picker
      ref="durFexcepcional"
      v-model="form.dur_fexcepcional"
      title="Fecha y hora"
      :disabled="!form.inicio_extraordinario"
      :with-validation-provider="true"
      :validation-rules="formRules.dur_fexcepcional"
    />
    <b10-text-field
      v-model="form.dur_causa"
      clearable
      flat
      label="Causa"
      :disabled="!form.inicio_extraordinario"
      :with-validation-provider="true"
      :validation-rules="formRules.dur_causa"
    />
    <v-text-field
      v-model="form.dur_entidad_comunicada"
      clearable
      flat
      label="Entidad comunicada"
      :disabled="!form.inicio_extraordinario"
    />

    <!-- Más datos -->

    <b10-subheader
      label="Más datos"
      :icon="$vuetify.icons.values.info"
    />
    <b10-date-picker
      v-model="form.fcontrato"
      title="Fecha de contrato"
      :validation-rules="formRules.fcontrato"
      :with-validation-provider="true"
    />
    <b10-autocomplete
      v-model="form.idserie_contrato"
      :items="seriesContrato"
      item-value="idserie_contrato"
      item-text="descripcorta"
      label="Serie de contrato"
      clearable
      :with-validation-provider="true"
      :validation-rules="formRules.idserie_contrato"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ContratoEditFormData'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        objeto: null,
        tcontrato: null,
        inicio_extraordinario: null,
        dur_fexcepcional: null,
        dur_causa: null,
        dur_entidad_comunicada: null,
        fcontrato: null,
        idserie_contrato: null,
      },
      formRules: {
        objeto: { required: true },
        tcontrato: { required: true },
        dur_fexcepcional: { required_if: { target: 'inicio_extraordinario', value: true } },
        dur_causa: { required_if: { target: 'inicio_extraordinario', value: true } },
        fcontrato: { required: true },
        idserie_contrato: { required: true },
      },
      tcontratos: [],
      seriesContrato: [],
    }
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.tcontratos = resp.data.selectTcontrato.result.dataset
    this.seriesContrato = resp.data.selectSerieContrato.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      formData.tcontrato = _.find(this.tcontratos, { 'idtcontrato': formData.idtcontrato })
      this.form = formData
      // si tiene fecha de inicio extraordinario marco el check
      this.$set(this.form, 'inicio_extraordinario', !!this.form.dur_fexcepcional)
    },
    tcontratoSelected (value) {
      if (value) {
        this.$set(this.form, 'objeto', value.objeto)
      } else {
        this.$set(this.form, 'objeto', null)
      }
    },
    inicioExtraordinarioSelected (value) {
      if (!value) {
        this.$set(this.form, 'dur_fexcepcional', null)
        this.$set(this.form, 'dur_causa', null)
        this.$set(this.form, 'dur_entidad_comunicada', null)
      } else {
        this.$set(this.form, 'dur_fexcepcional', new Date())
      }
    },
  }
}
</script>