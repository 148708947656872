export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'contrato.update',
      {
        values: {
          idcontrato: id,
          objeto: formData.objeto,
          idtcontrato: formData.tcontrato.idtcontrato,
          dur_fexcepcional: formData.dur_fexcepcional,
          dur_causa: formData.dur_causa,
          dur_entidad_comunicada: formData.dur_entidad_comunicada,
          fcontrato: formData.fcontrato,
          idserie_contrato: formData.idserie_contrato,
        },
      },
    )
  },
}
